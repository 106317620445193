@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");

@font-face {
  font-family: "Mirage";
  src: local("Mirage"),
    url("./assets/fonts/MADE\ Mirage\ Medium\ PERSONAL\ USE.otf")
      format("opentype");
  font-weight: normal;
}

* {
  font-family: "Tenor Sans", sans-serif;
}

.topfoldheader {
  font-size: 54px;
}

.link {
  color: #b9ac92;
  font-size: 12px !important;
  border-bottom: 1px solid #b9ac92;
}

div {
  font-size: 16px !important;
}

.headings {
  color: #f28c40 !important;
  font-size: 32px !important;
  font-family: Mirage !important;
  font-weight: 500 !important;
}

.subheader {
  font-size: 21px !important;
  color: #595357;
}

.subheader2 {
  font-size: 16px !important;
  color: #d3af37;
}

.loading-message {
  margin: 0px auto;
  position: absolute;
  top: 250px;
  left: 45%;
  animation: animated 10s ease-in-out infinite;
}

hr {
  color: #595357;
  height: 1px;
}

.hero-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background: rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
}


// .img-ht {
//   height: 411px !important;
// }

// .img-htc {
//   height: 1000px !important;
// }
