@import url(https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:"Mirage";src:local("Mirage"),url("/static/media/MADE Mirage Medium PERSONAL USE.c934e0ca.otf") format("opentype");font-weight:normal}*{font-family:"Tenor Sans", sans-serif}.topfoldheader{font-size:54px}.link{color:#b9ac92;font-size:12px !important;border-bottom:1px solid #b9ac92}div{font-size:16px !important}.headings{color:#f28c40 !important;font-size:32px !important;font-family:Mirage !important;font-weight:500 !important}.subheader{font-size:21px !important;color:#595357}.subheader2{font-size:16px !important;color:#d3af37}.loading-message{margin:0px auto;position:absolute;top:250px;left:45%;-webkit-animation:animated 10s ease-in-out infinite;animation:animated 10s ease-in-out infinite}hr{color:#595357;height:1px}.hero-overlay{position:absolute;top:0;left:0;width:100%;height:100vh;background:rgba(0,0,0,0.4);overflow-x:hidden}

